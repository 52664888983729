import React, { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IMAGE_ROOT_URL, SERVICE_IMAGE_ROOT_URL } from '../../services/api';
import { useEffect } from 'react';
import { BottomNav } from '../../components/components';
import { getCookie, setCookie } from '../../helper/_cookieHelper';
import { v4 as uuidv4 } from 'uuid';
import { useHotel } from '../../context/HotelContext';
import { Modal } from 'antd';
import { useLanguage } from '../../context/LanguageContext';
import { authRequiredRoutes } from '../../services/Typecode';
import { CheckCircleTwoTone } from '@ant-design/icons';
import landingIcon from '../../assets/icons/language-icon.svg';
import ReactGA from 'react-ga4';

const Landing = () => {
    const navigate = useNavigate();
    const { hotelDetails, serviceList } = useHotel();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { langList,lang } = useLanguage();

    useEffect(() => {
        let hotelID = localStorage.getItem('hotel_id');
        if (!hotelID) {
            navigate('/page-not-found', { replace: true });
        }
    }, []);
    
    useEffect(() => {
        window.scrollTo(0, 0);
        hasLanguagePreferences();
    }, [langList]);

    const cardClickhandler = (service) => {
        let queryparams = `lang=${lang}`
        if (service.type === 'about' || service.type === 'welcome') {
            localStorage.setItem(
                '__welcome_cover',

                `https://master-vserve.s3.ap-south-1.amazonaws.com/hotel_services/${encodeURIComponent(
                    service.banner
                )}`
            );
        }
        let requestUrl = '';
        switch (service.type) {
            case 'content':
                requestUrl = 'service_content';
                break;
            case 'connect':
                requestUrl = 'place-request';
                break;
            default:
                requestUrl = service.type;
                break;
        }
        const hasToken = localStorage.getItem('hotel_token') || false;
        const isAuthRequired = authRequiredRoutes.includes(requestUrl);
        const hotelViewOnly = JSON.parse(
            localStorage.getItem('isHotelViewOnly')
        );
        if (!hotelViewOnly && !hasToken && isAuthRequired) {
            navigate(`/login?request=${requestUrl}&${queryparams}`);
            return;
        }

        if (service.type === 'feed') {
            const isCookie = getCookie('_uid');
            if (!isCookie) {
                let id = uuidv4();
                setCookie('_uid', id, 400);
            }
        }
        if (service.type === 'link') {
            window.open(service?.content);
            return;
        }
        if (service.type === 'content') {
            navigate(`/${requestUrl}?${queryparams}`, { state: { data: service?.content } });
        } else {
            navigate(`/${requestUrl}?${queryparams}`, {
                state: {
                    coverImg: service.inner_banner
                        ? `${SERVICE_IMAGE_ROOT_URL}${service.inner_banner}`
                        : `${SERVICE_IMAGE_ROOT_URL}${service.banner}`,
                },
            });
        }
    };
    const globeIconClickHandler = () => {
        setIsModalOpen(true);
        ReactGA.event({
            category: 'home_page',
            action: 'lang_globe_icon_clicked',
        });
    };

    const LandingNavigation = () => (
        <div className='header-new border-b border-primaryBorder flex items-center w-full sticky top-0 bg-white z-10'>
            <div className='flex items-center justify-between pl-2 pr-2 w-full h-full py-1'>
                <div className='flex items-center gap-4 h-full justify-between w-full'>
                    <div className='w-6 h-6'></div>
                    <img
                        src={IMAGE_ROOT_URL + hotelDetails?.logo}
                        alt='primary'
                        className='w-full h-full object-contain max-w-28'
                    />
                    {langList.length > 0 ? (
                        <div
                            className='language-selector'
                            onClick={() => globeIconClickHandler()}
                        >
                            <img
                                src={landingIcon}
                                alt='Language'
                                className='language-icon'
                            />
                        </div>
                    ) : (
                        <div className='w-6 h-6'></div>
                    )}
                </div>
            </div>
        </div>
    );
    const hasLanguagePreferences = () => {
        try {
            const response = localStorage.getItem('lang');
            if (langList.length === 0 || response) {
                setIsModalOpen(false);
            } else {
                setIsModalOpen(true);
            }
        } catch (error) {}
    };

    return (
        <Fragment>
            <div
                className={
                    'homepage-container ' +
                    (isModalOpen ? 'lang-modal-open' : '')
                }
            >
                <LandingNavigation />
                <div className='allservices-container'>
                    {serviceList?.map(
                        (service) =>
                            Boolean(service.enabled) &&
                            service.type !== 'mini_bar' && (
                                <ServiceCard
                                    key={service.id}
                                    service={service}
                                    cardClickhandler={cardClickhandler}
                                />
                            )
                    )}
                </div>
            </div>
            <BottomNav />
            {isModalOpen && (
                <LanguagePopup
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                />
            )}
        </Fragment>
    );
};

export default Landing;

const ServiceCard = (props) => {
    const { service = {}, cardClickhandler } = props;
    const { hotelDetails } = useHotel();

    return (
        <div
            key={service.id}
            className={
                `service ${service.size_type} ${service.type} ` +
                (hotelDetails?.guest_primary_color ? 'apply-theme' : '')
            }
            style={{
                backgroundImage: `url(${SERVICE_IMAGE_ROOT_URL}${encodeURIComponent(
                    service.banner
                )})`,
            }}
            onClick={() => cardClickhandler(service)}
        >
            <div className='service_title'>{service.name}</div>
        </div>
    );
};
/*

    <div className={styles.landing}>
      {getBanner(services[0])}
      <div className={styles.twoCardsPerRow}>
      {getBanner(services[1])}
      {getBanner(services[2])}
      </div>
      <div className={styles.twoCardsPerRow}>
      {getBanner(services[3])}
      {getBanner(services[4])}
      </div>
      <div className={styles.twoCardsPerRow}>
      {getBanner(services[5])}
      {getBanner(services[6])}
      </div>
    </div>

*/

const LanguagePopup = (props) => {
    const { setIsModalOpen, isModalOpen } = props;
    const { langList, changeLanguage, lang } = useLanguage();
    const [language, setlanguage] = useState(lang);

    const onContinueClickHandler = () => {
        changeLanguage(language, true);
        setIsModalOpen(false);
    };
    return (
        <Modal
            title='Select language'
            open={isModalOpen}
            onOk={() => onContinueClickHandler()}
            onCancel={() => setIsModalOpen(false)}
            rootClassName='language-modal'
            centered
            width='18rem'
            okText='Continue'
            cancelButtonProps={{
                hidden: true,
            }}
        >
            <div className='content-area'>
                {langList.map((item) => (
                    <div
                        className={
                            'lang-card ' +
                            (item.lang_code === language ? 'selected' : '')
                        }
                        onClick={() => setlanguage(item.lang_code)}
                    >
                        <span>{item.lang_name}</span>
                        {item.lang_code === language && (
                            <span>
                                <CheckCircleTwoTone twoToneColor='green' />
                            </span>
                        )}
                    </div>
                ))}
            </div>
        </Modal>
    );
};
